import { defineMessages } from 'react-intl';

const productDetailMessages = defineMessages({
    title: {
        id: 'ProductDetail.productDetailMessages.title',
        description: 'Title Message of Product Detail Page',
        defaultMessage: 'Send a Gift Card'
    },
    digitalHeading: {
        id: 'ProductDetail.productDetailMessages.digitalHeading',
        description: 'Heading of Digital Card',
        defaultMessage: 'Send an eGift'
    },
    digitalInfo: {
        id: 'ProductDetail.productDetailMessages.digitalInfo',
        description: 'Info of Digital Card',
        defaultMessage: 'Delivered via Email, SMS/Share a link'
    },
    physicalHeading: {
        id: 'ProductDetail.productDetailMessages.physicalHeading',
        description: 'Heading of Physical Card',
        defaultMessage: 'Send it by Mail'
    },
    physicalInfo: {
        id: 'ProductDetail.productDetailMessages.physicalInfo',
        description: 'Info of Physical Card',
        defaultMessage: 'Have it Delivered to Home'
    },
    priceRange: {
        id: 'ProductDetail.productDetailMessages.priceRange',
        description: 'Price range of the card',
        defaultMessage: 'Range '
    },
    customAmountLabel: {
        id: 'ProductDetail.productDetailMessages.customAmountLabel',
        description: 'Custom Amount label name',
        defaultMessage: 'Custom Amount'
    }
});

export default productDetailMessages;
