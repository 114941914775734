import React, { useState, useEffect, useMemo } from 'react';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames/dedupe';
import { connect, useDispatch, useSelector } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Big from 'big.js';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import PropTypes from 'prop-types';
import { getItemCurrencyList, getSubTotal } from '../item/itemSelectors';
import {
    getElectronicDenoms,
    getMaxEgcValue,
    getMaxPlasticValue, getMaxTransactionAmount,
    getMinEgcValue, getMinPlasticValue, getPlasticDenoms,
    isFixedEgcCatalogPresent,
    isFixedPlasticCatalogPresent
} from '../catalogs/catalogSelectors';
import AmountLabel from '../amount/AmountLabel';
import {
    giftCardFieldNames,
    newItemCurrency,
    newItemDenomination,
    newItemReduxForm, newItemSelectedGroupOrdinal
} from '../item/newItemForm';
import { getCurrencySymbol } from '../intl/getCurrencySymbol';
import CustomAmount, { customAmountNumberFormatter } from '../amount/CustomAmount';
import amountMessages from '../amount/amountMessages';
import { getFormattedAmountWithCurrency, fixDecimalFormat, getCurrencyFractionDigits } from '../utils/numberUtils';
import { DenomPickerField } from '../denomination/DenomPickerField';
import validate from '../recipient/validate';
import CardType from './CardType';
import { getDigitalFaceplateGroups } from '../faceplate/faceplateSelectors';
import productDetailMessages from './ProductDetailMessages';
import { setCurrency, translateCurrency } from '../intl/intlModule';

const OptionsSection = ({
    isPlastic, intl
}) => {
    const dispatch = useDispatch();
    const itemCurrencyList = useSelector(state => getItemCurrencyList(state));
    const isFixedCatalog = useSelector(
        state => (isPlastic ? isFixedPlasticCatalogPresent(state) : isFixedEgcCatalogPresent(state))
    );
    const denominations = useSelector(state => (isPlastic ? getPlasticDenoms(state) : getElectronicDenoms(state)));
    const selectedDenomination = useSelector(state => newItemDenomination(state));
    const currencyCode = useSelector(state => newItemCurrency(state));
    const currencySymbol = getCurrencySymbol(intl, currencyCode);

    
    const minDenomAllowed = useSelector(state => (isPlastic ? getMinPlasticValue(state) : getMinEgcValue(state)));
    const maxDenomAllowed = useSelector(state => (isPlastic ? getMaxPlasticValue(state) : getMaxEgcValue(state)));
    const amountRange = intl.formatMessage(amountMessages.amountRange, {
        min: getFormattedAmountWithCurrency(intl, minDenomAllowed, currencyCode),
        max: getFormattedAmountWithCurrency(intl, maxDenomAllowed, currencyCode)
    });
    const maxTransaction = useSelector(state => parseFloat(getMaxTransactionAmount(state).toFixed(2)));
    const subTotal = useSelector(state => parseFloat(getSubTotal(state).toFixed(2)));
    const maxDenom = parseFloat(new Big(maxTransaction).minus(subTotal).toFixed(2));

    const [isCustomAmountLabel, setIsCustomAmountLabel] = useState(false);

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const currency = params.get('currency') ? String(params.get('currency')) : currencyCode;

    const availableDenom = useMemo(() => denominations && denominations.filter(value => value <= maxDenom).map((item) => {
        const fractionDigits = getCurrencyFractionDigits(currencyCode);
        const value = fixDecimalFormat(item, fractionDigits);
        return value;
    }).toJS());

    useEffect(() => {
        if (itemCurrencyList.includes(currency)) {
            dispatch(setCurrency(translateCurrency(currency)));
        } else {
            dispatch(setCurrency(translateCurrency(currencyCode)));
        }

        const isDenomValuePresent = availableDenom && availableDenom.includes(selectedDenomination);
        setIsCustomAmountLabel(isDenomValuePresent);
    }, []);

    const amountCurrencyPickerClasses = classNames(
        'amount-currency-picker',
        { 'no-currency-picker': itemCurrencyList.size <= 1 }
    );

    const amountLabelProps = {
        component: AmountLabel,
        amount: selectedDenomination,
        name: giftCardFieldNames.AMOUNT_FIELD,
        cashbotName: 'amount',
        helper: '',
        prefix: currencySymbol.prefix,
        suffix: currencySymbol.suffix
    };

    const isAmountValidator = (value) => {
        const validationContainer = document.getElementById('amount-input-validation');
        if (validationContainer) {
            validationContainer.innerHTML = '';
            const maxDenomVal = Number(maxDenomAllowed.toString());
            const minDenomVal = Number(minDenomAllowed.toString());
            if (value > maxDenomVal || value < minDenomVal) {
                const errorDiv = document.createElement('div');
                const amountRangeError = intl.formatMessage(amountMessages.amountRangeErr, {
                    min: getFormattedAmountWithCurrency(intl, minDenomAllowed, currencyCode),
                    max: getFormattedAmountWithCurrency(intl, maxDenomAllowed, currencyCode)
                });
                errorDiv.textContent = amountRangeError;
                validationContainer.appendChild(errorDiv);
            }
        }
    };

    const customAmountFieldProps = {
        name: giftCardFieldNames.AMOUNT_FIELD,
        component: CustomAmount,
        label: intl.formatMessage(amountMessages.customAmountLabel),
        incLabel: intl.formatMessage(amountMessages.customAmountIncrement),
        decLabel: intl.formatMessage(amountMessages.customAmountDecrement),
        cashbotName: 'amount',
        currencyCode,
        intl,
        hideAmountInput: isFixedCatalog,
        format: value => customAmountNumberFormatter(intl, value, currencyCode, true),
        amountRange,
        validate: (value) => {
            isAmountValidator(value);
        }
    };

    const denomPickerProps = {
        name: giftCardFieldNames.AMOUNT_FIELD,
        denominations,
        currencyCode,
        maxDenom,
        doFocus: false,
        intl
    };

    const amountMesaage = amountMessages.title;
    const containerProps = {
        heading: intl.formatMessage(amountMesaage),
        HeadingElement: 'h1'
    };

    const cutomAmountEventHandler = () => {
        setIsCustomAmountLabel(false);
    };

    return (
        <div className='container'>
            <h1>{intl.formatMessage(productDetailMessages.title)}</h1>
            <div className="options">
                <CardType />
                <div className='amount-box'>
                    <Container {...containerProps} />
                    <div className='amount-picker'>
                        <DenomPickerField {...denomPickerProps} />
                        <div className={amountCurrencyPickerClasses}>
                            {isFixedCatalog
                                ? <Field {...amountLabelProps} />
                                : (
                                    <React.Fragment>
                                        {isCustomAmountLabel
                                            ? (
                                                <button
                                                    className='custom-amount-overlay show'
                                                    onClick={cutomAmountEventHandler}
                                                    type='button'
                                                >
                                                    {intl.formatMessage(productDetailMessages.customAmountLabel)}
                                                </button>
                                            )
                                            : (
                                                <React.Fragment>
                                                    <span className='cutom-amount-wrapper show'>
                                                        <Field {...customAmountFieldProps} />
                                                    </span>
                                                </React.Fragment>
                                            )}
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                    <span className='amount-range'>
                        {intl.formatMessage(productDetailMessages.priceRange)}
                        {amountRange}
                    </span>
                </div>
            </div>
        </div>
    );
};

OptionsSection.propTypes = {
    intl: intlShape.isRequired,
    isPlastic: PropTypes.bool
};

OptionsSection.defaultProps = {
    isPlastic: false
};

const mapStateToProps = state => ({
    groups: getDigitalFaceplateGroups(state),
    groupOrdinal: newItemSelectedGroupOrdinal(state)
});

export default connect(mapStateToProps)(injectIntl(newItemReduxForm(OptionsSection, { validate })));
